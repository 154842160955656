<template>
  <div class="subtitle-display" ref="subtitleContainer" @scroll="handleScroll">
    <div class="subtitle-list">
      <div 
        v-for="(subtitle, index) in subtitles" 
        :key="index" 
        :class="{ 'subtitle-item': true, 'current-subtitle': isCurrentSubtitle(subtitle) }"
        :ref="isCurrentSubtitle(subtitle) ? 'currentSubtitle' : null"
        @click="seekToSubtitle(subtitle)"
      >
        <span class="timestamp">{{ formatTime(subtitle.start) }}</span>
        <p class="subtitle-text">{{ subtitle.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubtitleDisplay',
  props: ['subtitles', 'currentTime'],
  data() {
    return {
      userScrolling: false,  // 用于检测用户是否正在滚动
      scrollTimeout: null,   // 用于重置用户滚动状态的计时器
    }
  },
  methods: {
    isCurrentSubtitle(subtitle) {
      return this.currentTime >= subtitle.start && this.currentTime < subtitle.end;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    seekToSubtitle(subtitle) {
      console.log('Seeking to:', subtitle.start); // 添加日志
      this.$emit('seek', subtitle.start);
    },
    handleScroll() {
      this.userScrolling = true;
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.userScrolling = false;
      }, 1000);  // 用户停止滚动1秒后重置状态
    }
  },
  watch: {
    // 移除自动滚动的 watch
  }
}
</script>

<style scoped>
.subtitle-display {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  max-height: 50vh;
  overflow-y: auto;
}

.subtitle-list {
  display: flex;
  flex-direction: column;
}

.subtitle-item {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subtitle-item:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.timestamp {
  min-width: 50px;
  margin-right: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.subtitle-text {
  flex-grow: 1;
  margin: 0;
  color: #333;
  font-size: 1rem;
  text-align: left;
}

.current-subtitle {
  background-color: rgba(74, 144, 226, 0.2);
}

.current-subtitle .subtitle-text {
  color: #4a90e2;
  font-weight: bold;
}
</style>
