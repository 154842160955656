<template>
  <div class="home-page">
    <h1>{{ $t('homePage.welcome') }}</h1>
    <div class="features">
      <div class="feature">
        <i class="fas fa-upload"></i>
        <h2>{{ $t('homePage.uploadAudio') }}</h2>
        <p>{{ $t('homePage.uploadDescription') }}</p>
      </div>
      <div class="feature">
        <i class="fas fa-language"></i>
        <h2>{{ $t('homePage.generateSubtitles') }}</h2>
        <p>{{ $t('homePage.generateDescription') }}</p>
      </div>
      <div class="feature">
        <i class="fas fa-edit"></i>
        <h2>{{ $t('homePage.editSubtitles') }}</h2>
        <p>{{ $t('homePage.editDescription') }}</p>
      </div>
    </div>
    <button @click="startApp" class="btn btn-primary start-button">{{ $t('homePage.startUsing') }}</button>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: ['isLoggedIn'],
  methods: {
    startApp() {
      if (this.isLoggedIn) {
        this.$emit('start');
      } else {
        this.$emit('showLogin');
      }
    }
  }
}
</script>

<style scoped>
.home-page {
  text-align: center;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.feature {
  flex: 1;
  margin: 0 1rem;
}

.feature i {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.start-button {
  font-size: 1.2rem;
  padding: 0.75rem 2rem;
}
</style>
