<template>
  <div 
    class="audio-uploader"
    @dragover.prevent
    @drop.prevent="handleDrop"
    :class="{ 'drag-over': isDragging }"
  >
    <div class="upload-area" @click="triggerFileInput">
      <i class="fas fa-cloud-upload-alt"></i>
      <p>{{ isDragging ? '释放以上传文件' : '拖拽文件到这里或点击上传' }}</p>
      <input 
        ref="fileInput"
        type="file" 
        @change="uploadFile" 
        accept="audio/*"
        style="display: none;"
      >
    </div>
    <div v-if="selectedFile" class="file-info">
      <p>已选择文件: {{ selectedFile.name }}</p>
    </div>
    <button 
      @click="generateSubtitles" 
      class="generate-btn" 
      :disabled="!selectedFile || isGenerating"
    >
      {{ isGenerating ? '正在生成字幕...' : '生成字幕' }}
    </button>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { getIdToken } from 'firebase/auth';

export default {
  name: 'AudioUploader',
  data() {
    return {
      isDragging: false,
      selectedFile: null,
      isGenerating: false
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadFile(event) {
      const file = event.target.files[0];
      if (file) {
        const maxSize = 50 * 1024 * 1024; // 50MB
        if (file.size > maxSize) {
          this.$emit('upload-error', '文件大小不能超50MB');
          return;
        }
        this.selectedFile = file;
      }
    },
    handleDrop(event) {
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('audio/')) {
        this.uploadFile({ target: { files: [file] } });
      } else {
        this.$emit('upload-error', '请上传音频文件');
      }
    },
    async generateSubtitles() {
      if (!this.selectedFile) return;

      this.isGenerating = true;
      try {
        const uploadResult = await this.saveFileToServer(this.selectedFile);
        this.$emit('upload-completed', uploadResult.url, this.selectedFile.name, uploadResult.filePath);
        this.$emit('generate-subtitles');
      } catch (error) {
        console.error('上传或生成字幕失败:', error);
        this.$emit('upload-error', error.message);
      } finally {
        this.isGenerating = false;
      }
    },
    async saveFileToServer(file) {
      const formData = new FormData()
      formData.append('file', file)

      let idToken = '';
      if (auth.currentUser) {
        idToken = await getIdToken(auth.currentUser);
      }

      const response = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`
        },
        body: formData
      })

      if (!response.ok) {
        const errorText = await response.text()
        throw new Error(`文件上传失败: ${response.status} ${errorText}`)
      }

      const result = await response.json()
      return {
        filePath: result.filePath,
        url: result.url
      }
    }
  },
  mounted() {
    const uploader = this.$el;
    
    uploader.addEventListener('dragenter', (e) => {
      e.preventDefault();
      this.isDragging = true;
    });
    
    uploader.addEventListener('dragleave', (e) => {
      e.preventDefault();
      this.isDragging = false;
    });
  }
}
</script>

<style scoped>
.audio-uploader {
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 2px dashed #ced4da;
}

.upload-area {
  padding: 3rem;
  cursor: pointer;
}

.upload-area i {
  font-size: 3rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.upload-area p {
  margin: 0;
  color: #6c757d;
}

.drag-over {
  background-color: #e9ecef;
  border-color: #007bff;
}

.file-info {
  margin-top: 1rem;
}

.generate-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.generate-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
</style>
