import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    navbar: {
      title: 'Audio to Subtitle',
      login: 'Login',
      signup: 'Sign Up',
      logout: 'Logout'
    },
    homePage: {
      welcome: 'Welcome to Audio to Subtitle',
      uploadAudio: 'Upload Audio',
      uploadDescription: 'Supports various audio formats, easily upload your files.',
      generateSubtitles: 'Generate Subtitles',
      generateDescription: 'Utilizing advanced AI technology to swiftly generate precise subtitles.',
      editSubtitles: 'Edit Subtitles',
      editDescription: 'Intuitive interface for easy editing and adjustment of subtitles.',
      startUsing: 'Start Using'
    },
    sidebar: {
      Shortcut: 'Shortcut',
      RecentFiles: 'Recent Files'
    },
    recentFiles: {
      title: 'Recent Files',
      name: 'Name',
      uploadedTime: 'Uploaded Time',
      duration: 'Duration',
      status: 'Status',
      uploadNew: 'Upload New File',
      statuses: {
        completed: 'Completed',
        processing: 'Processing',
        error: 'Error'
      },
      transcribe: 'Transcribe Files'
    },
    subtitles: {
      generate: 'Generate Subtitles',
      generating: 'Generating Subtitles...'
    },
    // 添加其他英文翻译
  },
  zh: {
    navbar: {
      title: '音频转字幕',
      login: '登录',
      signup: '注册',
      logout: '退出登录'
    },
    homePage: {
      welcome: '欢迎使用音频转字幕',
      uploadAudio: '上传音频',
      uploadDescription: '支持多种音频格式，轻松上传您的文件。',
      generateSubtitles: '生成字幕',
      generateDescription: '使用先进的AI技术，快速生成准确的字幕。',
      editSubtitles: '编辑字幕',
      editDescription: '直观的界面，轻松编辑和调整字幕。',
      startUsing: '开始使用'
    },
    sidebar: {
      Shortcut: '快捷导航',
      RecentFiles: '最近文件'
    },
    recentFiles: {
      title: '最近文件',
      name: '名称',
      uploadedTime: '上传时间',
      duration: '时长',
      status: '状态',
      uploadNew: '上传新文件',
      statuses: {
        completed: '已完成',
        processing: '处理中',
        error: '错误'
      },
      transcribe: '转录文件'
    },
    subtitles: {
      generate: '生成字幕',
      generating: '正在生成字幕...'
    },
    // 添加其他中文翻译
  }
}

export default messages
