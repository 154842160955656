<template>
  <div class="modal-overlay">
    <div class="modal">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Email</label>
          <input v-model="email" type="email" id="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="password" type="password" id="password" required>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
      <button @click="loginWithGoogle" class="btn btn-secondary">Login with Google</button>
      <p class="switch-auth">
        Don't have an account? <a href="#" @click.prevent="switchToSignup">Sign up</a>
      </p>
      <button @click="$emit('close')" class="btn btn-tertiary">Close</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: ''
    }
  },
  methods: {
    async login() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$emit('close');
      } catch (error) {
        console.error('Login error:', error);
        this.errorMessage = 'Invalid email or password. Please try again.';
      }
    },
    async loginWithGoogle() {
      try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        this.$emit('close');
      } catch (error) {
        console.error('Google login error:', error);
        this.errorMessage = 'Failed to login with Google. Please try again.';
      }
    },
    switchToSignup() {
      this.$emit('switch-to-signup');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

h2 {
  margin-top: 0;
  color: var(--primary-color);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.btn {
  width: 100%;
  margin-top: 1rem;
}

.btn-tertiary {
  background-color: #95a5a6;
  color: white;
}

.error-message {
  color: red;
  margin-top: 1rem;
}

.switch-auth {
  margin-top: 1rem;
  text-align: center;
}

.switch-auth a {
  color: var(--primary-color);
  text-decoration: none;
}

.switch-auth a:hover {
  text-decoration: underline;
}
</style>
