<template>
  <div class="payment-success">
    <div class="success-card">
      <i class="fas fa-check-circle success-icon"></i>
      <h2>支付成功！</h2>
      <p>{{ statusMessage }}</p>
      <div class="subscription-details" v-if="subscription">
        <p>订阅计划: {{ getPlanName }}</p>
        <p>到期时间: {{ formatDate(subscription.endDate) }}</p>
      </div>
      <div class="buttons">
        <button @click="backToHome" class="btn btn-primary">
          返回首页
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'
import { getIdToken } from 'firebase/auth'
import { onAuthStateChanged } from 'firebase/auth'

export default {
  name: 'PaymentSuccess',
  data() {
    return {
      subscription: null,
      loading: true,
      error: null,
      statusMessage: '正在验证支付状态...'
    }
  },
  computed: {
    getPlanName() {
      const planMap = {
        'monthly': '月度会员',
        'yearly': '年度会员'
      }
      return planMap[this.subscription?.plan] || '未知计划'
    }
  },
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleString()
    },
    backToHome() {
      this.$router.push('/')
    },
    async verifyPayment() {
      try {
        const sessionId = this.$route.query.session_id
        if (!sessionId) {
          throw new Error('未找到支付会话ID')
        }

        const user = await new Promise((resolve) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe()
            resolve(user)
          })
        })

        if (!user) {
          this.statusMessage = '请先登录'
          return
        }

        const idToken = await getIdToken(user)
        const response = await fetch('/api/verify-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({ sessionId })
        })

        if (!response.ok) {
          throw new Error('支付验证失败')
        }

        const data = await response.json()
        this.subscription = data.subscription
        this.statusMessage = '您已成功升级为会员！'
        this.loading = false
      } catch (error) {
        console.error('Payment verification error:', error)
        this.error = error.message
        this.statusMessage = '支付验证失败，请联系客服'
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.verifyPayment()
  }
}
</script>

<style scoped>
.payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
}

.success-card {
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
}

.success-icon {
  font-size: 4rem;
  color: #2ecc71;
  margin-bottom: 1rem;
}

.subscription-details {
  margin: 2rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.buttons {
  margin-top: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #2563eb;
}
</style> 