<template>
  <div class="audio-player-wrapper">
    <div class="audio-player">
      <div class="player-container">
        <div class="audio-info">
          <span class="audio-name">{{ audioName }}</span>
        </div>
        <audio :src="audioSrc" @timeupdate="onTimeUpdate" ref="audioElement"></audio>
        <div class="controls">
          <button @click="playPause" class="play-pause-btn">
            <i :class="isPlaying ? 'fas fa-pause' : 'fas fa-play'"></i>
          </button>
          <div class="progress-container">
            <input 
              type="range" 
              min="0" 
              :max="duration" 
              :value="currentTime" 
              @input="seek" 
              class="progress-bar"
            >
            <div class="time-display">
              <span>{{ formatTime(currentTime) }}</span>
              <span>{{ formatTime(duration) }}</span>
            </div>
          </div>
          <div class="additional-controls">
            <div class="volume-control">
              <i class="fas fa-volume-up"></i>
              <input 
                type="range" 
                min="0" 
                max="1" 
                step="0.1" 
                v-model="volume" 
                @input="setVolume" 
                class="volume-slider"
              >
            </div>
            <div class="playback-speed">
              <select v-model="playbackRate" @change="setPlaybackRate">
                <option value="0.5">0.5x</option>
                <option value="0.75">0.75x</option>
                <option value="1" selected>1x</option>
                <option value="1.25">1.25x</option>
                <option value="1.5">1.5x</option>
                <option value="2">2x</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioPlayer',
  props: ['audioSrc', 'audioName'],
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      error: '',
      volume: 1,
      playbackRate: 1
    }
  },
  methods: {
    onTimeUpdate(event) {
      this.currentTime = event.target.currentTime;
      this.$emit('timeupdate', this.currentTime);
    },
    playPause() {
      if (this.$refs.audioElement.paused) {
        this.$refs.audioElement.play().catch(e => {
          console.error('Play error:', e);
          this.error = `播放错误: ${e.message}`;
        });
        this.isPlaying = true;
      } else {
        this.$refs.audioElement.pause();
        this.isPlaying = false;
      }
    },
    seek(event) {
      if (typeof event === 'number') {
        this.$refs.audioElement.currentTime = event;
      } else if (event && event.target) {
        this.$refs.audioElement.currentTime = event.target.value;
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    setVolume() {
      this.$refs.audioElement.volume = this.volume;
    },
    setPlaybackRate() {
      this.$refs.audioElement.playbackRate = this.playbackRate;
    }
  },
  watch: {
    audioSrc: {
      handler(newSrc) {
        console.log("New audio source in AudioPlayer:", newSrc);
        this.isPlaying = false;
        this.currentTime = 0;
        this.error = '';
        this.$nextTick(() => {
          if (this.$refs.audioElement) {
            this.$refs.audioElement.load();
            this.$refs.audioElement.onloadedmetadata = () => {
              this.duration = this.$refs.audioElement.duration;
              console.log('Audio metadata loaded, duration:', this.duration);
            };
            this.$refs.audioElement.onerror = (e) => {
              console.error('Audio load error:', e);
              this.error = `音频加载错误: ${e.target.error.message}`;
            };
          }
        });
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.audio-player-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 0px;
}

.audio-player {
  width: 50%;
  background-color: transparent;
  border-radius: 10px;
  padding: 0px;
  font-family: Arial, sans-serif;
}

.player-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.audio-info {
  margin-bottom: 10px;
  text-align: center;
}

.audio-name {
  font-weight: bold;
  font-size: 18px;
}

.controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.play-pause-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
}

.progress-container {
  flex-grow: 1;
  margin-right: 15px;
  min-width: 200px;
}

.progress-bar {
  width: 100%;
  margin-bottom: 5px;
}

.time-display {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
}

.additional-controls {
  display: flex;
  align-items: center;
}

.volume-control {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.volume-slider {
  width: 80px;
  margin-left: 10px;
}

.playback-speed select {
  padding: 5px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}
</style>
