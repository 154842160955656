<template>
  <div class="recent-files">
    <div class="header">
      <h2>{{ $t('recentFiles.title') }}</h2>
      <div class="membership-status">
        <div class="usage-info">
          <span v-if="!isPremium">
            本月剩余次数：{{ remainingUsage }}/{{ maxMonthlyUsage }}
          </span>
          <span class="membership-badge" :class="{ 'is-premium': isPremium }">
            <i class="fas fa-crown" v-if="isPremium"></i>
            {{ isPremium ? 'Premium会员' : '免费用户' }}
          </span>
        </div>
        <button 
          v-if="!isPremium" 
          @click="showUpgradeModal" 
          class="btn btn-premium"
        >
          <i class="fas fa-crown"></i>
          升级到Premium
        </button>
      </div>
      <div class="header-buttons">
        <button @click="refresh" class="btn btn-secondary refresh-btn">
          <i class="fas fa-sync-alt"></i> 刷新
        </button>
        <button @click="$emit('transcribe')" class="btn btn-primary transcribe-btn">
          {{ $t('recentFiles.transcribe') }}
        </button>
      </div>
    </div>
    <div v-if="loading" class="loading">
      <i class="fas fa-spinner fa-spin"></i> 加载中...
    </div>
    <div v-else-if="error" class="error">
      <p>{{ error }}</p>
      <button @click="handleRetry" class="btn btn-primary retry-btn">
        <i class="fas fa-redo"></i> 重试
      </button>
    </div>
    <table v-else>
      <thead>
        <tr>
          <th>{{ $t('recentFiles.name') }}</th>
          <th>{{ $t('recentFiles.uploadedTime') }}</th>
          <th>{{ $t('recentFiles.duration') }}</th>
          <th>{{ $t('recentFiles.status') }}</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="file in recentFiles" :key="file.id">
          <td @click="selectFile(file)">{{ file.name }}</td>
          <td @click="selectFile(file)">{{ formatDate(file.uploadedTime) }}</td>
          <td @click="selectFile(file)">{{ formatDuration(file.duration) }}</td>
          <td @click="selectFile(file)">
            <span :class="['status-badge', `status-${file.status}`]">
              {{ getStatusText(file.status) }}
            </span>
          </td>
          <td class="actions">
            <div class="dropdown">
              <button class="btn btn-icon" @click.stop="toggleDropdown(file.id)" title="更多操作">
                ⋮
              </button>
              <div v-if="activeDropdown === file.id" class="dropdown-menu">
                <button @click.stop="showRenameDialog(file)" class="dropdown-item">
                  重命名
                </button>
                <button @click.stop="confirmDelete(file)" class="dropdown-item delete">
                  删除
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="recentFiles.length === 0 && !loading" class="no-files">
      暂无记录
    </div>
    <div v-if="showRenameModal" class="modal-overlay" @click="closeRenameModal">
      <div class="modal-content" @click.stop>
        <h3>重命名文件</h3>
        <input 
          type="text" 
          v-model="newFileName" 
          class="rename-input"
          @keyup.enter="handleRename"
          ref="renameInput"
        >
        <div class="modal-buttons">
          <button @click="closeRenameModal" class="btn btn-secondary">取消</button>
          <button @click="handleRename" class="btn btn-primary">确定</button>
        </div>
      </div>
    </div>
    <div v-if="showDeleteModal" class="modal-overlay" @click="closeDeleteModal">
      <div class="modal-content" @click.stop>
        <h3>确认删除</h3>
        <p>确定要删除文件 "{{ selectedFile?.name }}" 吗？</p>
        <div class="modal-buttons">
          <button @click="closeDeleteModal" class="btn btn-secondary">取消</button>
          <button @click="handleDelete" class="btn btn-danger">删除</button>
        </div>
      </div>
    </div>
    <div v-if="showPremiumModal" class="modal-overlay" @click="closePremiumModal">
      <div class="modal-content premium-modal" @click.stop>
        <h3>升级到Premium会员</h3>
        <div class="premium-plans">
          <div 
            v-for="plan in premiumPlans" 
            :key="plan.id" 
            class="premium-plan"
            :class="{ 'selected': selectedPlan === plan.id }"
            @click="selectPlan(plan.id)"
          >
            <h4>{{ plan.name }}</h4>
            <div class="price">¥{{ plan.price }}</div>
            <div class="duration">{{ plan.duration }}</div>
            <ul class="features">
              <li v-for="feature in plan.features" :key="feature">
                <i class="fas fa-check"></i> {{ feature }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="closePremiumModal" class="btn btn-secondary">取消</button>
          <button 
            @click="handlePurchase" 
            class="btn btn-premium"
            :disabled="!selectedPlan"
          >
            立即购买
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, limit, getDoc, serverTimestamp } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';

export default {
  data() {
    return {
      recentFiles: [],
      loading: false,
      error: null,
      activeDropdown: null,
      showRenameModal: false,
      showDeleteModal: false,
      selectedFile: null,
      newFileName: '',
      showPremiumModal: false,
      selectedPlan: null,
      isPremium: false,
      remainingUsage: 0,
      maxMonthlyUsage: 3,
      premiumPlans: [
        {
          id: 'monthly',
          name: '月度会员',
          price: 29,
          duration: '1个月',
          features: ['无限次转换', '优先处理', '高级客服支持']
        },
        {
          id: 'yearly',
          name: '年度会员',
          price: 299,
          duration: '12个月',
          features: ['无限次转换', '优先处理', '高级客服支持', '两个月免费']
        }
      ],
      stripe: null,
    }
  },
  async mounted() {
    await this.fetchRecentFiles();
    await this.fetchUserMembership();
    document.addEventListener('click', () => {
      this.activeDropdown = null;
    });
  },
  async created() {
    // 初始化 Stripe
    this.stripe = await loadStripe('your_publishable_key'); // 替换为你的Stripe公钥
  },
  methods: {
    async fetchRecentFiles() {
      if (!this.$root.user) {
        console.log('No user logged in');
        return;
      }

      this.loading = true;
      this.error = null;
      
      try {
        console.log('Current user:', this.$root.user.uid);
        const filesRef = collection(db, 'files');
        
        const q = query(
          filesRef, 
          where('userId', '==', this.$root.user.uid),
          limit(10)
        );

        console.log('Executing query...');
        const querySnapshot = await getDocs(q);
        console.log('Query result:', querySnapshot.size, 'documents');
        
        this.recentFiles = querySnapshot.docs
          .map(doc => {
            const data = doc.data();
            console.log('Document data:', data);
            return {
              id: doc.id,
              ...data
            };
          })
          .sort((a, b) => {
            if (!a.uploadedTime || !b.uploadedTime) return 0;
            return b.uploadedTime.seconds - a.uploadedTime.seconds;
          });

      } catch (error) {
        console.error('Error details:', error);
        this.error = '加载记录失败: ' + error.message;
      } finally {
        this.loading = false;
      }
    },
    async selectFile(file) {
      if (file.subtitles && file.filePath) {
        try {
          // 获取当前用户的 token
          const auth = getAuth();
          const token = await auth.currentUser.getIdToken();
          
          // 获取新的签名URL
          const response = await fetch('/api/get-signed-url', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              filePath: file.filePath
            })
          });
          
          if (!response.ok) {
            throw new Error('Failed to get signed URL');
          }
          
          const data = await response.json();
          this.$emit('file-selected', {
            url: data.url,
            name: file.name,
            subtitles: file.subtitles
          });
        } catch (error) {
          console.error('Error getting signed URL:', error);
          // 显示错误提示
          this.error = '获取音频文件失败: ' + error.message;
        }
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      return new Date(timestamp.seconds * 1000).toLocaleString()
    },
    formatDuration(seconds) {
      if (!seconds) return '0:00';
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    },
    refresh() {
      this.fetchRecentFiles();
    },
    handleRetry() {
      this.error = null;
      this.fetchRecentFiles();
    },
    getStatusText(status) {
      const statusMap = {
        'processing': '处理中',
        'completed': '已完成',
        'error': '失败'
      };
      return statusMap[status] || status;
    },
    toggleDropdown(fileId) {
      this.activeDropdown = this.activeDropdown === fileId ? null : fileId;
    },
    showRenameDialog(file) {
      this.selectedFile = file;
      this.newFileName = file.name;
      this.showRenameModal = true;
      this.activeDropdown = null;
      this.$nextTick(() => {
        this.$refs.renameInput?.focus();
      });
    },
    async handleRename() {
      if (!this.newFileName.trim()) return;
      
      try {
        const fileRef = doc(db, 'files', this.selectedFile.id);
        await updateDoc(fileRef, {
          name: this.newFileName.trim()
        });
        
        // 更新本地数据
        const index = this.recentFiles.findIndex(f => f.id === this.selectedFile.id);
        if (index !== -1) {
          this.recentFiles[index].name = this.newFileName.trim();
        }
        
        this.closeRenameModal();
      } catch (error) {
        console.error('Error renaming file:', error);
      }
    },
    closeRenameModal() {
      this.showRenameModal = false;
      this.selectedFile = null;
      this.newFileName = '';
    },
    confirmDelete(file) {
      this.selectedFile = file;
      this.showDeleteModal = true;
      this.activeDropdown = null;
    },
    async handleDelete() {
      try {
        await deleteDoc(doc(db, 'files', this.selectedFile.id));
        
        // 从本地数据中移除
        this.recentFiles = this.recentFiles.filter(f => f.id !== this.selectedFile.id);
        
        this.closeDeleteModal();
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedFile = null;
    },
    async fetchUserMembership() {
      if (!this.$root.user) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', this.$root.user.uid));
        const userData = userDoc.data();
        
        this.isPremium = userData?.membership?.status === 'premium';
        this.remainingUsage = userData?.monthlyUsage?.remaining ?? 3;
        
        // 如果是新的一个月，重置使用次数
        const lastResetDate = userData?.monthlyUsage?.lastReset?.toDate();
        if (!lastResetDate || this.isNewMonth(lastResetDate)) {
          await this.resetMonthlyUsage();
        }
      } catch (error) {
        console.error('Error fetching user membership:', error);
      }
    },
    
    isNewMonth(lastResetDate) {
      const now = new Date();
      return lastResetDate.getMonth() !== now.getMonth() || 
             lastResetDate.getFullYear() !== now.getFullYear();
    },
    
    async resetMonthlyUsage() {
      if (!this.$root.user) return;
      
      try {
        await updateDoc(doc(db, 'users', this.$root.user.uid), {
          'monthlyUsage.remaining': 3,
          'monthlyUsage.lastReset': serverTimestamp()
        });
        this.remainingUsage = 3;
      } catch (error) {
        console.error('Error resetting monthly usage:', error);
      }
    },
    
    showUpgradeModal() {
      this.showPremiumModal = true;
    },
    
    closePremiumModal() {
      this.showPremiumModal = false;
      this.selectedPlan = null;
    },
    
    selectPlan(planId) {
      this.selectedPlan = planId;
    },
    
    async handlePurchase() {
      if (!this.selectedPlan) return;
      
      try {
        const response = await fetch('/api/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await this.$root.user.getIdToken()}`
          },
          body: JSON.stringify({
            planId: this.selectedPlan
          })
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || '创建支付会话失败');
        }

        const { url } = await response.json();
        
        // 使用 window.location.href 进行重定向
        if (url) {
          window.location.href = url;
        } else {
          throw new Error('No checkout URL received');
        }
        
      } catch (error) {
        console.error('Error processing purchase:', error);
        this.$emit('show-error', '购买失败：' + error.message);
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', () => {
      this.activeDropdown = null;
    });
  }
}
</script>

<style scoped>
.recent-files {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
  max-width: 900px;
  margin: 2rem;
  margin-right: auto;
  width: 90%;
  margin-left: 50px;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  gap: 2rem;
}

.transcribe-btn {
  padding: 0 1.5rem;
  height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

h2 {
  margin-top: 0;
  color: var(--primary-color);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: var(--primary-color);
}

tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.btn-primary {
  margin-top: 1rem;
}

.loading, .error, .no-files {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 1rem;
  border-radius: 4px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-buttons {
  display: flex;
  margin-left: auto;
  gap: 1rem;
  align-items: center;
}

.refresh-btn {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 40px;
}

.refresh-btn i {
  font-size: 0.9em;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.loading i {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.retry-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.retry-btn i {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .recent-files {
    margin: 1rem;
    padding: 1rem;
    width: calc(100% - 2rem);
  }

  .header {
    flex-direction: column;
    gap: 1rem;
  }

  .header-buttons {
    margin-left: 0;
    width: 100%;
    justify-content: space-between;
  }

  .refresh-btn,
  .transcribe-btn {
    flex: 1;
    justify-content: center;
  }

  table {
    font-size: 0.9rem;
  }

  th, td {
    padding: 0.5rem;
  }
}

.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-processing {
  background-color: #fef3c7;
  color: #92400e;
}

.status-completed {
  background-color: #d1fae5;
  color: #065f46;
}

.status-error {
  background-color: #fee2e2;
  color: #991b1b;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.status-processing {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.actions {
  text-align: center;
  position: relative;
  width: 60px;
}

.btn-icon {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #666;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon:hover {
  color: var(--primary-color);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  min-width: 120px;
  margin-top: 4px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: var(--text-color);
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-item.delete {
  color: #dc3545;
}

.dropdown-item.delete:hover {
  background-color: #fee2e2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.rename-input {
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #bb2d3b;
}

tr td {
  cursor: pointer;
}

/* 确保操作列不会被点击触发选择 */
tr td.actions {
  cursor: default;
}

.membership-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.usage-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.membership-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #e5e7eb;
  color: #374151;
}

.membership-badge.is-premium {
  background-color: #fef3c7;
  color: #92400e;
}

.membership-badge i {
  color: #f59e0b;
}

.btn-premium {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f59e0b;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-premium:hover {
  background-color: #d97706;
  transform: translateY(-1px);
}

.btn-premium i {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .membership-status {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
  }

  .usage-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .btn-premium {
    width: 100%;
    justify-content: center;
  }
}

.premium-modal {
  max-width: 800px;
}

.premium-plans {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
}

.premium-plan {
  flex: 1;
  padding: 2rem;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.premium-plan.selected {
  border-color: #f59e0b;
  background-color: #fff7ed;
}

.premium-plan h4 {
  margin: 0;
  color: #1f2937;
  font-size: 1.25rem;
}

.premium-plan .price {
  font-size: 2rem;
  font-weight: bold;
  color: #f59e0b;
  margin: 1rem 0;
}

.premium-plan .duration {
  color: #6b7280;
  margin-bottom: 1rem;
}

.premium-plan .features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.premium-plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: #374151;
}

.premium-plan .features li i {
  color: #10b981;
}
</style>
