<template>
  <div class="payment-cancel">
    <div class="cancel-card">
      <i class="fas fa-times-circle cancel-icon"></i>
      <h2>支付已取消</h2>
      <p>您可以随时重新尝试购买会员</p>
      <div class="buttons">
        <button @click="tryAgain" class="btn btn-primary">
          重新购买
        </button>
        <button @click="backToHome" class="btn btn-secondary">
          返回首页
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentCancel',
  methods: {
    tryAgain() {
      // 返回到会员购买页面并显示升级按钮
      this.$router.push('/?showUpgrade=true');
    },
    backToHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.payment-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 2rem;
}

.cancel-card {
  background: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
}

.cancel-icon {
  font-size: 4rem;
  color: #ef4444;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #2563eb;
}

.btn-secondary {
  background-color: #6b7280;
  color: white;
  border: none;
}

.btn-secondary:hover {
  background-color: #4b5563;
}
</style> 