import { createRouter, createWebHashHistory } from 'vue-router'
import PaymentCancel from '../components/PaymentCancel.vue'
import PaymentSuccess from '../components/PaymentSuccess.vue'

const routes = [
  {
    path: '/payment/cancel',
    name: 'PaymentCancel',
    component: PaymentCancel
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router 