<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-left">
        <div class="navbar-logo" @click="goToHome">
          <img src="@/assets/logo.png" alt="Logo" class="logo">
        </div>
      </div>
      <h1 class="navbar-title">{{ $t('navbar.title') }}</h1>
      <div class="navbar-right">
        <div class="language-selector">
          <button @click="toggleLanguageMenu" class="language-button">
            <i :class="currentLanguageIcon"></i>
            {{ currentLanguageName }}
          </button>
          <div v-if="showLanguageMenu" class="language-menu">
            <button @click="changeLanguage('en')" :class="{ active: $i18n.locale === 'en' }">
              <i class="fas fa-flag-usa"></i> English
            </button>
            <button @click="changeLanguage('zh')" :class="{ active: $i18n.locale === 'zh' }">
              <i class="fas fa-flag"></i> 中文
            </button>
          </div>
        </div>
        <template v-if="user">
          <span class="user-email">{{ user.email }}</span>
          <button @click="logout" class="btn btn-secondary">{{ $t('navbar.logout') }}</button>
        </template>
        <template v-else>
          <button @click="showLoginModal" class="btn btn-primary">{{ $t('navbar.login') }}</button>
          <button @click="showSignupModal" class="btn btn-secondary">{{ $t('navbar.signup') }}</button>
        </template>
      </div>
    </nav>
    <div class="main-container" :class="{ 'with-sidebar': showSidebar }">
      <aside v-if="showSidebar" class="sidebar">
        <div class="sidebar-content">
          <h3 class="sidebar-title">{{ $t('sidebar.Shortcut') }}</h3>
          <button @click="showRecentFiles" class="btn btn-primary sidebar-button">
            <i class="fas fa-history"></i> {{ $t('sidebar.RecentFiles') }}
          </button>
          <!-- 可以在这里添加更多的侧边栏选项 -->
        </div>
      </aside>
      <main :class="{ 'with-sidebar': showSidebar }">
        <PaymentSuccess v-if="showSuccessPage" />
        <PaymentCancel v-else-if="showCancelPage" />
        <HomePage 
          v-else-if="showHomePage" 
          @start="startApp" 
          @showLogin="showLoginModal"
          :isLoggedIn="!!user"
        />
        <RecentFiles 
          v-else-if="showingRecentFiles"
          ref="recentFiles"
          @file-selected="handleFileSelected"
          @transcribe="showTranscribeInterface"
        />
        <div v-else-if="showingTranscribeInterface" class="transcribe-interface">
          <AudioUploader 
            @upload-completed="handleUploadCompleted"
            @upload-error="handleUploadError"
            @generate-subtitles="generateSubtitles"
          />
        </div>
        <div v-else-if="showingAudioPlayer" class="player-container">
          <div class="player-header">
            <button @click="backToRecentFiles" class="btn btn-secondary back-btn">
              <i class="fas fa-arrow-left"></i> 返回
            </button>
            <h2>{{ audioName }}</h2>
          </div>
          <div class="media-player">
            <SubtitleDisplay 
              v-if="subtitles.length > 0"
              :subtitles="subtitles" 
              :currentTime="currentTime"
              @seek="handleSeek"
            />
            <AudioPlayer 
              :audioSrc="audioSrc" 
              :audioName="audioName"
              @timeupdate="handleTimeUpdate"
              ref="audioPlayer"
            />
          </div>
        </div>
      </main>
    </div>
    <footer v-if="showingAudioPlayer && audioSrc" class="audio-footer">
      <AudioPlayer 
        :audioSrc="audioSrc" 
        :audioName="audioName"
        @timeupdate="handleTimeUpdate"
        ref="audioPlayer"
      />
    </footer>
    <LoginModal 
      v-if="isLoginModalVisible" 
      @close="closeLoginModal" 
      @switch-to-signup="showSignupModal"
    />
    <SignupModal 
      v-if="isSignupModalVisible" 
      @close="closeSignupModal" 
      @switch-to-login="showLoginModal"
    />
    <!-- 添加上传模态框 -->
    <div v-if="showUploadModal" class="modal-overlay">
      <div class="modal-content">
        <button @click="closeUploadModal" class="close-btn">&times;</button>
        <h2>上传音频文件</h2>
        <AudioUploader 
          @upload-completed="handleUploadCompleted"
          @upload-error="handleUploadError"
          @generate-subtitles="generateSubtitles"
        />
      </div>
    </div>
    <!-- 添加成功提示 -->
    <div v-if="showSuccessMessage" class="success-message">
      {{ successMessage }}
    </div>
  </div>
</template>

<script>
import AudioUploader from './components/AudioUploader.vue'
import AudioPlayer from './components/AudioPlayer.vue'
import SubtitleDisplay from './components/SubtitleDisplay.vue'
import LoginModal from './components/LoginModal.vue'
import SignupModal from './components/SignupModal.vue'
import RecentFiles from './components/RecentFiles.vue'
import HomePage from './components/HomePage.vue'
import PaymentCancel from './components/PaymentCancel.vue'
import PaymentSuccess from './components/PaymentSuccess.vue'
import { auth } from '@/firebase';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { db } from '@/firebase';
import { doc, setDoc, collection, addDoc, serverTimestamp, getDoc } from 'firebase/firestore';

export default {
  name: 'App',
  components: {
    AudioUploader,
    AudioPlayer,
    SubtitleDisplay,
    LoginModal,
    SignupModal,
    RecentFiles,
    HomePage,
    PaymentCancel,
    PaymentSuccess
  },
  data() {
    return {
      audioSrc: '',
      audioName: '',
      subtitles: [],
      currentTime: 0,
      error: '',
      user: null,
      isLoginModalVisible: false,
      isSignupModalVisible: false,
      isSidebarOpen: false,
      showingRecentFiles: false,
      audioFilePath: '',
      isGeneratingSubtitles: false,
      showHomePage: true,
      showLanguageMenu: false,
      showingTranscribeInterface: false,
      showUploadModal: false,
      showSuccessMessage: false,
      successMessage: '',
      showingAudioPlayer: false,
    }
  },
  mounted() {
    onAuthStateChanged(auth, async (user) => {
      this.user = user;
      if (user) {
        await this.ensureUserRecord(user);
      }
    });
    // 可以添加从本地存储加载上次使用的语言设置
    const savedLocale = localStorage.getItem('locale')
    if (savedLocale) {
      this.$i18n.locale = savedLocale
    }
  },
  computed: {
    currentLanguageIcon() {
      return this.$i18n.locale === 'en' ? 'fas fa-flag-usa' : 'fas fa-flag'
    },
    currentLanguageName() {
      return this.$i18n.locale === 'en' ? 'English' : '中文'
    },
    showSidebar() {
      return this.user && !this.showHomePage;
    },
    showCancelPage() {
      return this.$route.path === '/payment/cancel';
    },
    showSuccessPage() {
      return this.$route.path === '/payment/success';
    }
  },
  methods: {
    async ensureUserRecord(user) {
      try {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) {
          // Create new user record
          await setDoc(userRef, {
            email: user.email,
            createdAt: serverTimestamp(),
            monthlyUsage: {
              remaining: 3,
              lastReset: serverTimestamp()
            },
            membership: {
              status: 'free',
              startDate: null,
              endDate: null,
              plan: null
            }
          });
          console.log('Created new user record');
        }
      } catch (error) {
        console.error('Error ensuring user record:', error);
      }
    },
    startApp() {
      if (this.user) {
        this.showHomePage = false;
        this.showingRecentFiles = true;
      } else {
        this.showLoginModal();
      }
    },
    goToHome() {
      this.showHomePage = true;
      this.showingRecentFiles = false;
      this.audioSrc = '';
      this.audioName = '';
      this.subtitles = [];
      this.error = '';
    },
    handleTimeUpdate(time) {
      this.currentTime = time
    },
    // 处理上传错误的事件。
    handleUploadError(errorMessage) {
      this.error = errorMessage;
    },
    // 处理字幕搜索的事。
    // 接收时间参数，并调用音频播放器的 seek 方法。 
    handleSeek(time) {
      console.log('App: Seeking to:', time); // 添加日志
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.seek(time);
      }
    },
    showLoginModal() {
      this.isLoginModalVisible = true
      this.isSignupModalVisible = false
    },
    closeLoginModal() {
      this.isLoginModalVisible = false
    },
    showSignupModal() {
      this.isSignupModalVisible = true
      this.isLoginModalVisible = false
    },
    closeSignupModal() {
      this.isSignupModalVisible = false
    },
    async logout() {
      try {
        await auth.signOut();
        // 登出后重置应用状态
        this.showHomePage = true;
        this.showingRecentFiles = false;
        this.showingTranscribeInterface = false;
        this.audioSrc = '';
        this.audioName = '';
        this.subtitles = [];
        this.error = '';
        // 可以添加一个提示消息，告诉用户已成功登出
        console.log('Successfully logged out');
      } catch (error) {
        console.error('Logout error:', error);
        // 可以添加个错误提示
        this.error = 'Failed to logout. Please try again.';
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    showRecentFiles() {
      this.showingRecentFiles = true;
      this.showingAudioPlayer = false;
      this.showHomePage = false;
      this.showingTranscribeInterface = false;
      this.audioSrc = '';
      this.audioName = '';
      this.subtitles = [];
    },
    handleFileSelected(file) {
      this.audioSrc = file.url;
      this.audioName = file.name;
      this.subtitles = file.subtitles;
      this.showingRecentFiles = false;
      this.showingTranscribeInterface = false;
      this.showHomePage = false;
      this.showingAudioPlayer = true;
    },
    handleUploadCompleted(audioUrl, fileName, filePath) {
      this.audioSrc = audioUrl;
      this.audioName = fileName;
      this.audioFilePath = filePath;
      this.subtitles = [];
      this.closeUploadModal();
    },
    async generateSubtitles() {
      if (!this.audioFilePath) {
        this.error = '请先上传音频文件';
        return;
      }
      
      console.log('Starting transcription...'); 
      this.isGeneratingSubtitles = true;
      this.error = '';
      
      try {
        // 首先创建一个处理中的记录
        let fileDocRef = null;
        if (auth.currentUser) {
          try {
            const fileData = {
              userId: auth.currentUser.uid,
              name: this.audioName,
              url: this.audioSrc,
              subtitles: [],
              uploadedTime: serverTimestamp(),
              duration: await this.getAudioDuration(this.audioSrc),
              status: 'processing',
              filePath: this.audioFilePath
            };

            console.log('Creating file record...', fileData);
            const docRef = await addDoc(collection(db, 'files'), fileData);
            fileDocRef = docRef;
          } catch (firebaseError) {
            console.error('Error creating initial record:', firebaseError);
            throw new Error('创建记录失败');
          }
        }

        let idToken = '';
        if (auth.currentUser) {
          idToken = await getIdToken(auth.currentUser);
        }

        const response = await fetch('/api/transcribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({ audioPath: this.audioFilePath })
        });

        if (!response.ok) {
          const error = await response.json();
          // 如果是使用次数限制错误，更新文件状态为error
          if (fileDocRef) {
            await setDoc(fileDocRef, {
              status: 'error',
              error: error.message || `转录失败: ${response.status}`
            }, { merge: true });
          }
          
          // 显示错误消息
          this.error = error.message || `转录失败: ${response.status}`;
          
          // 如果是会员限制，显示升级提示
          if (response.status === 403) {
            this.$refs.recentFiles?.showUpgradeModal();
          }
          
          // 直接返回到历史记录界面
          this.showingRecentFiles = true;
          this.showingTranscribeInterface = false;
          this.showHomePage = false;
          this.closeUploadModal();
          
          // 刷新历史记录
          this.$nextTick(() => {
            if (this.$refs.recentFiles) {
              this.$refs.recentFiles.fetchRecentFiles();
            }
          });
          
          return;
        }

        const result = await response.json();
        
        if (fileDocRef) {
          await setDoc(fileDocRef, {
            subtitles: result.subtitles,
            status: 'completed'
          }, { merge: true });
          
          this.successMessage = '字幕生成成功！';
          this.showSuccessMessage = true;
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 3000);
        }

        // 更新状态并显示结果
        this.subtitles = result.subtitles;
        this.closeUploadModal();
        this.showingRecentFiles = true;
        this.showingTranscribeInterface = false;
        this.showHomePage = false;

        this.$nextTick(() => {
          if (this.$refs.recentFiles) {
            this.$refs.recentFiles.fetchRecentFiles();
          }
        });

      } catch (error) {
        console.error('生成字幕失败:', error);
        
        // 更新文件状态error
        if (fileDocRef) {
          await setDoc(fileDocRef, {
            status: 'error',
            error: error.message || '生成字幕失败'
          }, { merge: true });
        }
        
        // 显示错误消息
        this.error = error.message || '生成字幕失败，请稍后重试';
        
        // 直接返回到历史记录界面
        this.showingRecentFiles = true;
        this.showingTranscribeInterface = false;
        this.showHomePage = false;
        this.closeUploadModal();
        
        // 刷新历史记录
        this.$nextTick(() => {
          if (this.$refs.recentFiles) {
            this.$refs.recentFiles.fetchRecentFiles();
          }
        });
      } finally {
        this.isGeneratingSubtitles = false;
      }
    },
    toggleLanguageMenu() {
      this.showLanguageMenu = !this.showLanguageMenu
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      this.showLanguageMenu = false
    },
    showTranscribeInterface() {
      this.showUploadModal = true;
    },
    closeUploadModal() {
      this.showUploadModal = false;
    },
    async getAudioDuration(audioUrl) {
      return new Promise((resolve) => {
        const audio = new Audio(audioUrl);
        audio.addEventListener('loadedmetadata', () => {
          resolve(Math.round(audio.duration));
        });
        audio.addEventListener('error', () => {
          resolve(0); // 如果无法获取时长，返回 0
        });
      });
    },
    backToRecentFiles() {
      this.showingAudioPlayer = false;
      this.showingRecentFiles = true;
      this.audioSrc = '';
      this.audioName = '';
      this.subtitles = [];
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newLocale) {
        localStorage.setItem('locale', newLocale)
      },
      immediate: true
    },
    '$route.query.showUpgrade': {
      handler(newVal) {
        if (newVal === 'true' && this.$refs.recentFiles) {
          this.$refs.recentFiles.showUpgradeModal();
        }
      },
      immediate: true
    }
  }
}
</script>

<style>
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f4f4f4;
  --text-color: #333;
  --border-color: #ddd;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-left, .navbar-right {
  flex: 1;
}

.navbar-title {
  flex: 2;
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-logo .logo {
  height: 40px;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.main-container {
  display: flex;
  flex-grow: 1;
}

.main-container.with-sidebar {
  padding-left: 250px; /* 侧边栏的固定宽度 */
}

.sidebar {
  position: fixed;
  left: 0;
  top: 80px; /* 根据你的导航栏高度调整 */
  bottom: 0;
  width: 200px;
  background-color: #2c3e50; /* 深色背景 */
  color: #ecf0f1; /* 浅色文字 */
  border-right: 1px solid #34495e;
  z-index: 1000;
  overflow-y: auto;
  transition: all 0.3s ease;
}

.sidebar-content {
  padding: 1.5rem;
}

.sidebar-title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #3498db; /* 突出显示的标题颜色 */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sidebar-button {
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #34495e;
  color: #ecf0f1;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sidebar-button:hover {
  background-color: #3498db;
}

.sidebar-button i {
  margin-right: 0.5rem;
}

main {
  flex-grow: 1;
}

main.with-sidebar {
  margin-left: 250px; /* 与侧边栏宽度相同 */
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.content-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
  margin-top: 2rem;
}

.error {
  color: #e74c3c;
  text-align: center;
  margin-top: 1rem;
}

.audio-footer {
  background-color: #fff;
  border-top: 1px solid var(--border-color);
  padding: 1rem;
}

.navbar-logo {
  cursor: pointer; /* 添加这行，使鼠标悬停在 logo 上时变为手型 */
}

.language-select {
  margin-right: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: white;
  cursor: pointer;
}

.language-selector {
  position: relative;
  margin-right: 1rem;
}

.language-button {
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem;
}

.language-button i {
  margin-right: 0.5rem;
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.language-menu button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
}

.language-menu button:hover,
.language-menu button.active {
  background-color: var(--background-color);
}

.language-menu button i {
  margin-right: 0.5rem;
}

.navbar-auth {
  display: flex;
  align-items: center;
}

.language-selector {
  margin-right: 1rem;
}

.user-email {
  margin-right: 1rem;
}

.transcribe-interface {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 2rem;
  margin-top: 2rem;
}

.button-container {
  margin-top: 1rem;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  padding: 1rem;
  border-radius: 4px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.media-player {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.player-container {
  padding: 2rem;
  max-width: 900px;
  margin: 2rem;
  margin-right: auto;
  width: 90%;
  margin-left: 50px;
}

.player-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.back-btn i {
  font-size: 0.9em;
}

.player-header h2 {
  margin: 0;
  flex-grow: 1;
  color: var(--primary-color);
}
</style>

