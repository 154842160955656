<template>
  <div class="modal-overlay">
    <div class="modal">
      <h2>Sign Up</h2>
      <form @submit.prevent="signup">
        <div class="form-group">
          <label for="email">Email</label>
          <input v-model="email" type="email" id="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="password" type="password" id="password" required>
        </div>
        <button type="submit" class="btn btn-primary">Sign Up</button>
      </form>
      <button @click="signupWithGoogle" class="btn btn-secondary">Sign Up with Google</button>
      <p class="switch-auth">
        Already have an account? <a href="#" @click.prevent="switchToLogin">Login</a>
      </p>
      <button @click="$emit('close')" class="btn btn-tertiary">Close</button>
      <p v-if="message" :class="{ 'success-message': isSuccess, 'error-message': !isSuccess }">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc, serverTimestamp } from 'firebase/firestore';

export default {
  data() {
    return {
      email: '',
      password: '',
      message: '',
      isSuccess: false
    }
  },
  methods: {
    async signup() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        await sendEmailVerification(userCredential.user);
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email: this.email,
          createdAt: serverTimestamp(),
          emailVerified: false,
          monthlyUsage: {
            remaining: 3,
            lastReset: serverTimestamp()
          },
          membership: {
            status: 'free',
            startDate: null,
            endDate: null,
            plan: null
          }
        });
        this.message = '注册成功！我们已向您的邮箱发送了验证链接，请验证后再登录。';
        this.isSuccess = true;
        setTimeout(() => {
          this.$emit('close');
          this.$emit('switch-to-login');
        }, 3000);
      } catch (error) {
        console.error('Signup error:', error);
        this.message = `注册错误: ${error.message}`;
        this.isSuccess = false;
      }
    },
    async signupWithGoogle() {
      try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        this.$emit('close');
      } catch (error) {
        console.error('Google signup error:', error);
        this.message = `Google signup error: ${error.message}`;
        this.isSuccess = false;
      }
    },
    switchToLogin() {
      this.$emit('switch-to-login');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
}

h2 {
  margin-top: 0;
  color: var(--primary-color);
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.btn {
  width: 100%;
  margin-top: 1rem;
}

.btn-tertiary {
  background-color: #95a5a6;
  color: white;
}

.success-message {
  color: green;
  margin-top: 1rem;
}

.error-message {
  color: red;
  margin-top: 1rem;
}

.switch-auth {
  margin-top: 1rem;
  text-align: center;
}

.switch-auth a {
  color: var(--primary-color);
  text-decoration: none;
}

.switch-auth a:hover {
  text-decoration: underline;
}
</style>
