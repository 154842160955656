import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyD0KzER-XZy0KEuPi6ellmRqOI5GrdLoQM",
  authDomain: "goodscript-f39e1.firebaseapp.com",
  projectId: "goodscript-f39e1",
  storageBucket: "goodscript-f39e1.firebasestorage.com",
  messagingSenderId: "17586446762",
  appId: "1:17586446762:web:a1f67fd5dd27caed420bd3",
  measurementId: "G-JN857DHDHZ"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
